<script>
export default {
  name: "DetailsList",
  components: {},

  props: {
    list: {
      type: Array,
      default: () => [],
    },

    middleLine: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      validator: (value) => ["l", "m"].includes(value),
      default: "l",
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>

<template>
  <ul class="details-list" :class="`details-list_${size}`">
    <li v-for="(item, index) in list" :key="index" class="details-list__item">
      <span>{{ item.left }}</span>
      <div v-if="middleLine" class="details-list__dot-line" />
      <span>{{ item.right }}</span>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.details-list {
  color: $dark-primary;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;

  &__item {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: flex-end;

    &:not(:last-child) {
      margin-bottom: $space-m;
    }

    > span {
      @include body-1;

      color: inherit;
      white-space: nowrap;

      &:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &__dot-line {
    border-bottom: 1px dotted $outline-light;
    height: 1px;
    flex-grow: 1;
    margin-bottom: 5px;
    min-width: 16px;
  }
}

.details-list_m {
  > .details-list__item {
    &:not(:last-child) {
      margin-bottom: $space-s;
    }

    > span {
      @include text-2;
    }
  }
}
</style>
