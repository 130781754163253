<script>
import ProfileCard from "@/components/ProfileCard.vue";
import DetailsList from "@/components/DetailsList.vue";
import Tag from "@/components/Tag.vue";

export default {
  name: "CardSalesman",
  components: { ProfileCard, DetailsList, Tag },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      detailsList: [
        { left: "Стоимость услуги", right: "10 000 RC" },
        { left: "Дата обращения", right: "16 октября, 14:07" },
        { left: "Номер заявки", right: "№23890967" },
      ],
    };
  },

  computed: {
    // regionsKeys() {
    //   return Object.keys(this.data.regions) || [];
    // },

    // captionTag() {
    //   if (this.data.regions.all) return "Все";
    //
    //   return this.data?.cities?.length;
    // },

    formattedServices() {
      if (!this.data.services.length) return [];

      const getNewArray = (arr) => {
        return arr.map((item) => ({
          left: item.name,
          right: `${this.transformNumberDischarge(item.price)} RC`,
        }));
      };

      if (this.data.services.length > 3) {
        // Разделяем массив на два подмассива
        const subarray1 = this.data.services.slice(0, 3); // первые три элемента
        const subarray2 = this.data.services.slice(3, 6); // следующие три элемента (или меньше, если не хватает)

        return [getNewArray(subarray1), getNewArray(subarray2)];
      } else {
        return [getNewArray(this.data.services), []];
      }
    },

    redirectParams() {
      return { name: "OtherServicesTsp", params: { id: this.data.id } };
    },
  },

  methods: {
    transformNumberDischarge(number) {
      return number.toLocaleString();
    },

    getCaptionTag(item) {
      if (item.all) return "Все";

      return item.cities?.length;
    },
  },
};
</script>

<template>
  <article class="card-salesman">
    <div class="card-salesman__profile-box">
      <ProfileCard
        v-if="data.tariff"
        class="card-salesman__profile"
        :name="data.name"
        :tariff="data.tariff"
        size="m"
        :avatar="data.avatar"
      />
    </div>

    <div class="card-salesman__line" />

    <div class="card-salesman__tags-box">
      <h6 class="card-salesman__title-regions">Регионы действия услуг</h6>

      <div class="card-salesman__tags">
        <Tag
          v-for="(item, index) in data.regions"
          :key="index"
          :title="item.region"
          :caption="getCaptionTag(item)"
        />
      </div>
    </div>

    <div class="card-salesman__line" />

    <div class="card-salesman__services">
      <div class="card-salesman__titles">
        <h6>Услуги и цены</h6>

        <span>({{ data.services.length }})</span>
      </div>

      <div class="card-salesman__lists">
        <DetailsList
          v-for="(item, index) in formattedServices"
          :key="index"
          :list="item"
          size="m"
          middle-line
        />
      </div>

      <router-link class="card-salesman__link" :to="redirectParams">
        Подробнее
      </router-link>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.card-salesman {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: $space-l;
  color: $dark-primary;
  background: $background-white;
  border-radius: $space-m;
  position: relative;

  @media (max-width: 1023px) {
    gap: $space-m;
    padding: $space-m;
  }

  &__profile-box {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (max-width: 1023px) {
      gap: $space-s;
    }
  }

  &__profile {
    flex-direction: row-reverse;
    justify-content: space-between;

    &::v-deep {
      > img {
        width: 56px !important;
        height: 56px !important;

        @media (max-width: 1023px) {
          width: 48px !important;
          height: 48px !important;
        }
      }
    }
  }

  &__tags-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: $space-xs;
    width: 100%;
    padding-right: 72px;

    @media (max-width: 1023px) {
      padding-right: 0;
    }
  }

  &__line {
    height: 0.5px;
    width: 100%;
    background: $outline-light;
  }

  &__titles {
    display: flex;
    gap: $space-xs;
    margin-bottom: $space-m;

    @media (max-width: 1023px) {
      margin-bottom: $space-s;
    }

    > h6 {
      @include text-2-bold;

      color: inherit;
    }

    > span {
      @include text-2;

      color: $dark-fifth;
    }
  }

  &__lists {
    gap: $space-xxl;
    display: grid;
    grid-template-columns: repeat(2, minmax(50px, 382px));

    @media (max-width: 1023px) {
      display: block;
    }

    > ul:last-child {
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  &__link {
    @include p2;

    opacity: 0;
    display: inline-block;
    position: absolute;
    padding: 8px 24px;
    text-decoration: none;
    color: $dark-primary;
    width: 100%;
    background: $gold-main;
    text-align: center;
    inset: 0;

    @media (max-width: 1023px) {
      position: static;
      opacity: 1;
      margin-top: $space-m;
      border-radius: 8px;
    }
  }

  &__title-regions {
    @include text-2-bold;

    color: $dark-primary;
  }
}
</style>
