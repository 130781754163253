<script>
export default {
  name: "NavigationCard",
  components: {},

  props: {
    title: {
      type: String,
      required: true,
    },

    counter: {
      type: Number,
      default: 0,
    },

    to: {
      type: Object,
      default: Object.create({}),
    },
  },

  data() {
    return {};
  },

  computed: {
    formatCounter() {
      if (!this.counter) return "";

      if (this.counter > 99) return this.counter + "+";

      return this.counter;
    },
  },

  methods: {},
};
</script>

<template>
  <div class="navigation-card">
    <slot class="" name="icon" />

    <div class="navigation-card__counter-box">
      <div v-if="counter" class="navigation-card__counter">
        <span>{{ formatCounter }}</span>
      </div>
    </div>

    <router-link :to="to" class="navigation-card__link">
      {{ title }}
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.navigation-card {
  position: relative;
  display: flex;
  gap: 12px;
  padding: $space-l;
  text-decoration: none;
  color: $dark-primary;
  background: $background-white;
  border-radius: 12px;
  width: fit-content;

  @media (max-width: 1023px) {
    padding: $space-m;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }

  &__link {
    @include body-1;

    text-decoration: none;
    color: $dark-primary;

    &:before {
      content: "";
      position: absolute;
      inset: 0;
    }
  }

  &__counter-box {
    padding: 3px;
    background: $background-grey;
    position: absolute;
    right: -4px;
    top: -7px;
    border-radius: 15px;
  }

  &__counter {
    background: $red;
    height: 24px;
    min-width: 24px;
    width: fit-content;
    display: grid;
    place-items: center;
    border-radius: 12px;
    padding: 0 7.5px;

    @media (max-width: 1023px) {
      height: 20px;
      min-width: 20px;
      border-radius: 10px;
      padding: 0 6px;
    }

    > span {
      @include text-2-bold;

      color: $light-primary;
    }
  }
}
</style>
