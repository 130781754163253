<script>
import NavigationCard from "@/components/NavigationCard.vue";
import MainSelect from "@/components/helpers/MainSelect.vue";
import CardSalesman from "@/components/transaction-support/CardSalesman.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import MainObserver from "@/components/helpers/MainObserver.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "TransactionSupportTsp",
  components: {
    MainSelect,
    NavigationCard,
    CardSalesman,
    MainLoader,
    MainObserver,
  },

  data() {
    return {
      selectedOption: {},
      isLoadingUnread: true,
    };
  },

  computed: {
    ...mapState({
      consultants: (state) => state.transactionSupport.consultants,
      isLoadingConsultants: (state) =>
        state.transactionSupport.isLoadingConsultants,
      linksConsultants: (state) => state.transactionSupport.linksConsultants,
      user: (state) => state.auth.user,
      services: (state) => state.transactionSupport.services,
    }),

    optionsFilters() {
      const arr = ["Все услуги"];
      arr.push(...this.services);
      return arr.map((item, index) => ({ id: index, name: item }));
    },

    countUnreadHistory() {
      if (!this.user || this.isLoadingUnread) return 0;

      return (
        this.user.bourse.unread_provided_count +
        this.user.bourse.unread_received_count
      );
    },
  },

  watch: {
    selectedOption: {
      handler(val) {
        this.interceptObserver(val.name);
      },
    },
  },

  methods: {
    ...mapActions({
      getConsultants: "transactionSupport/getConsultants",
      getFiltersService: "transactionSupport/getFiltersService",
      checkUser: "auth/checkUser",
    }),

    interceptObserver(filter = "") {
      if (!this.isLoadingConsultants) this.getConsultants(filter);
    },
  },

  mounted() {
    if (!this.consultants.length) this.getConsultants();

    this.getFiltersService();

    this.selectedOption = this.optionsFilters[0];

    this.checkUser().finally(() => {
      this.isLoadingUnread = false;
    });
  },
};
</script>

<template>
  <div class="transaction-support-tsp default-content-padding">
    <div class="transaction-support-tsp__header">
      <NavigationCard
        title="Мои услуги"
        :to="{
          name: 'OtherServicesTsp',
          params: { id: user.consultant_id || 'empty-profile' },
        }"
        class="transaction-support-tsp__navigation"
      >
        <template #icon>
          <div class="icon-box">
            <span class="icon-user-fill" />
          </div>
        </template>
      </NavigationCard>

      <NavigationCard
        title="История услуг"
        :to="{ name: 'HistoryService' }"
        :counter="countUnreadHistory"
        class="transaction-support-tsp__navigation"
      >
        <template #icon>
          <div class="icon-box">
            <span class="icon-schedule-fill" />
          </div>
        </template>
      </NavigationCard>
    </div>

    <div class="transaction-support-tsp__content">
      <MainSelect
        class="transaction-support-tsp__select"
        :options="optionsFilters"
        :selected-option.sync="selectedOption"
        style-type="background-white"
        :disable="isLoadingConsultants"
      />

      <div class="transaction-support-tsp__list">
        <span v-if="!consultants.length && !isLoadingConsultants">
          Консультанты еще не добавлены
        </span>

        <CardSalesman v-for="item in consultants" :key="item.id" :data="item" />

        <MainLoader v-if="isLoadingConsultants" />

        <MainObserver v-else @intersect="interceptObserver" />
      </div>
    </div>

    <!--    <router-link :to="{ name: 'HistoryService' }">HistoryService</router-link>-->
  </div>
</template>

<style lang="scss" scoped>
.transaction-support-tsp {
  padding: 0 $space-xxl $space-xxxl $space-xxl;
  display: flex;
  flex-direction: column;
  gap: $space-xxxl;

  @media (max-width: 1023px) {
    //padding: 40px $space-l;
    gap: $space-xl;
  }

  @media (max-width: 576px) {
    padding: 0 16px 32px 16px;
  }

  &__header {
    display: flex;
    gap: $space-m;
  }

  &__navigation {
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__content {
    max-width: 860px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 1023px) {
      gap: $space-m;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $space-m;

    > span {
      @include body-1;

      color: $dark-third;
    }
  }

  &__select::v-deep {
    .main-select {
      &__items {
        max-width: 240px !important;

        @media (max-width: 1023px) {
          max-width: 100% !important;
        }
      }

      &_background-white {
        max-width: 240px;
        width: 100%;

        @media (max-width: 1023px) {
          max-width: 100%;
        }
      }
    }
  }
}

.icon-box {
  background: $dark-primary;
  height: 24px;
  width: 24px;
  display: grid;
  place-items: center;
  border-radius: 50%;

  > span {
    height: 16px;
    aspect-ratio: 1;
    background: $background-white;
  }
}
</style>
